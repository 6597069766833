<template>
  <v-row class="match-height">
    <v-col
      cols="12"
      md="12"
    >
      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.cars')} / ${$t('btn.resize')}`">
        <v-card-text>
          <fragment v-if="!isLoading">
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="error"
                  class="mb-4 me-3"
                  @click="$router.push({ name: 'cars-update' })"
                >
                  <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                  <span>{{ $t('btn.back') }}</span>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="primary"
                  class="mb-4 me-3"
                  :loading="loading"
                  @click="save()"
                >
                  <span>{{ $t('btn.update') }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <clipper-basic
                  ref="clipper"
                  :src="'data:image/jpeg;base64,' + base64"
                  :ratio="3 / 2"
                  preview="clipper-preview"
                ></clipper-basic>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <clipper-preview
                  id="preview"
                  name="clipper-preview"
                />
              </v-col>
            </v-row>
          </fragment>
          <v-row v-else>
            <v-col
              cols="12"
              md="12"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css'
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
  mdiCrop,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
        mdiCrop,
      },
      imageSrc: null,
      pathImage: null,
      resource: 'local',
      loading: false,
      result: '',
      resultURL: '',
      base64: '',
    }
  },
  computed: {
    ...mapState({
      loadImg: state => state.app.loadImg,
    }),
  },
  created() {
    this.load()
  },
  methods: {
    ...mapMutations(['setImgLoading']),
    load() {
      if (sessionStorage.getItem('path-img-crop') !== null) {
        this.pathImage = sessionStorage.getItem('path-img-crop')
        if (sessionStorage.getItem('resource-img-crop') !== null) {
          this.resource = sessionStorage.getItem('resource-img-crop')
        }
      } else {
        this.$router.push({ name: 'cars-update' })
      }

      // this.imageSrc = this.rutaPublic + this.pathImage
      this.axios
        .post(
          'galery/base',
          { path: this.pathImage, resource: this.resource, product: 'cars' },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          this.base64 = response.data.path
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        })

      /* const image = new window.Image()
      image.src = this.rutaPublic + this.pathImage
      image.onload = () => {
        // set image only when it is loaded
        this.imageSrc = image
        console.log(this.imageSrc)
        this.isLoading = false
      } */
    },
    ...mapMutations(['deleteImgHotels']),
    save() {
      this.loading = true
      this.setImgLoading(true)

      // const canvas = this.$refs.clipper.clip()

      const canvas = this.$refs.clipper.clip() // call component's clip method
      this.resultURL = canvas.toDataURL('image/webp', 1) // canvas->image

      this.axios
        .post(
          'galery/base-put',
          {
            path: this.pathImage,
            base: this.resultURL,
            product: 'cars',
            product_id: sessionStorage.getItem('cars-id'),
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          if (response.data) {
            sessionStorage.removeItem('path-img-crop')
            sessionStorage.removeItem('resource-img-crop')
            this.$router.push({ name: 'cars-update' })
          } else {
            this.$toast.error('Problemas con la imagen')
          }
        })
        .finally(() => {
          setTimeout(() => {
            window.location.reload()
            this.setImgLoading(false)
            this.loading = false
          }, 200)
        })
    },
  },
}
</script>
